body {
  font-family: "Nanum Myeongjo", serif !important;
  font-weight: bold;
  font-style: normal;
  color: #000;
}

.ReactModal__Content {
  width: min-content;
}

.nanum-myeongjo-regular {
  font-family: "Nanum Myeongjo", serif;
  font-weight: 400;
  font-style: normal;
}

.sub-title {
  font-weight: bold;
}

.modal-content {
  display: flex;
  flex-direction: row;
}

.outer-layer {
  padding: 40px
}

h4 {
  margin: 0;
}

.instructions {
  padding-left: 50px;
}

.input-title {
  padding-top: 25px;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #B30202;
}

.top-radio {
  padding-left: 13px;
}

.rmsc {
  font-size: 16px;
  margin: 0;
  padding: 10px 20px 10px 20px;
  min-width: 289px;
}

.rmsc-textarea {
  font-size: 16px;
  margin: 0;
  padding: 10px;
  min-width: 450px;
  min-height: 89px;
}

.filter {
  font-size: 16px;
  margin: 0;
  padding: 10px 20px 10px 20px;
  min-width: 289px;
}

.email {
  position: relative;
  outline: 0;
  background-color:#fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-email {
  font-family: inherit;
  font-size: 16px;
  /* color: #aaa; */
  position: relative;
  padding: 0 10px;
  width: 100%;
  height: 38px;
  cursor: default;
  outline: 0;
}

textarea {
  outline: none;
  resize: none;
  overflow: auto;
  font-family: "Nanum Myeongjo", serif;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.Rsvp-button {
  border-radius: 30px;
  color: #111;
  font-family: "Nanum Myeongjo", serif;
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  overflow: hidden;
  padding: 12px 40px;
  width: auto;
  border: hidden;
  background-color: transparent;
  border: 1px solid #111;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.topcoat-text-input {
  width: 100%;
}

textarea::placeholder {
  font-size: 1rem;
  color: #9c9c9c;
  font-family: 'Roboto', sans-serif;
}

/* WebKit, Edge */
textarea::-webkit-input-placeholder {
  font-size: 1rem;
  color: #9c9c9c;
  font-family: "Nanum Myeongjo", serif;
}

/* Firefox 4-18 */
textarea:-moz-placeholder {
  font-size: 1rem;
  color: #9c9c9c;
  font-family: "Nanum Myeongjo", serif;
}

/* Firefox 19+ */
textarea::-moz-placeholder {
  font-size: 1rem;
  color: #9c9c9c;
  font-family: "Nanum Myeongjo", serif;
}

/* IE 10-11 */
textarea:-ms-input-placeholder {
  font-size: 1rem;
  color: #9c9c9c;
  font-family: "Nanum Myeongjo", serif;
}

/* Edge */
textarea::-ms-input-placeholder {
  font-size: 1rem;
  color: #9c9c9c;
  font-family: "Nanum Myeongjo", serif;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(46,46,51,.95);
}

.ReactModal__Content--after-open {
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  max-width: 500px;
  margin: 0 auto;
  bottom: 0%;
  background-color: #FFF;

}

.wrapper {
  background: yellow;
  position: relative;
  margin: 0 auto;
  border-radius: 12px 12px 0 0;
  height: calc(100vh - 115px);
  background: white;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 1rem 1rem;
}

